import useTranslation from 'hooks/useTranslation';

import SidebarItem from './SidebarItem';

function Sidebar() {
  const { TRANSLATIONS } = useTranslation();
  const ITEMS = [
    `${TRANSLATIONS.auth.sidebar.list1}`,
    `${TRANSLATIONS.auth.sidebar.list2}`,
    `${TRANSLATIONS.auth.sidebar.list3}`,
    `${TRANSLATIONS.auth.sidebar.list4}`,
    `${TRANSLATIONS.auth.sidebar.list5}`,
    //`${TRANSLATIONS.auth.sidebar.list6}`,
    //`${TRANSLATIONS.auth.sidebar.list7}`
  ];
  return (
    <div className="sidebar">
      <div className="text">
        <h3>{TRANSLATIONS.auth.sidebar.title}</h3>
      </div>
      <div className="items">
        <ul>
          {ITEMS.map((item) => (
            <SidebarItem key={item} label={item} />
          ))}
        </ul>
      </div>
      <style jsx>{`
        .sidebar {
          display: none;
          position: absolute;
          right: 0px;
          top: 0px;
          bottom: 0px;
          width: 40%;
          color: var(--white);
          background: var(--bg-login);
          border-right: var(--line);
          background-size: contain;
          background-position: bottom;
          background-repeat: no-repeat;
        }
        .text {
          font-size: 20px;
          font-weight: 300;
          line-height: 34px;
          margin-bottom: 2em;
        }
        .items {
        }
        ul {
          list-style: none;
          padding: 0;
        }
        @media all and (min-width: 992px) {
          .sidebar {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0px 50px;
          }
        }
        @media all and (min-width: 1200px) {
          .sidebar {
            padding: 0px 50px;
          }
        }
      `}</style>
    </div>
  );
}

export default Sidebar;
